import config from 'config'
import { processLocalizedURLAddress } from '@vue-storefront/core/helpers'
import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl'
import { TaskQueue } from '@vue-storefront/core/lib/sync'
import { Logger } from '@vue-storefront/core/lib/logger'
import { state } from 'core/modules/url/store/state'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import rootStore from '@vue-storefront/core/store'

export const calendarStore = {
  namespaced: true,
  state: {
    sourceToCarrierMap: {
      showroom: 'instore',
      omnipack: 'dhl_dhl24pl'
    },
    sourceToCalendarMap: {
      showroom: 'showroom',
      omnipack: 'all'
    },
    delivery: '',
    availableDeliveries: [],
    avaliableQuantity: null,
    period: null,
    availablePeriods: [],
    originalBookedDates: [],
    // bookedDates: [],
    bookedDatesFull: [],
    holidays: [],
    turnoverDays: {
      before: 0,
      after: 0
    },
    firstAvaliableDate: null,
    blockedDays: { weekdays: [1] },
    selectedDate: '',
    date: null,
    dateValidation: true
  },
  getters: {
    getFirstAvaliableDate: (state) => state.firstAvaliableDate,
    getBlockedDays: (state) => state.blockedDays,
    getBookedDatesFull: (state) => state.bookedDatesFull,
    // getBookedDates: (state) => state.bookedDates,
    getPeriods: (state) => state.availablePeriods,
    getActivePeriod: (state) => state.period,
    getDeliveries: (state) => state.availableDeliveries,
    getActiveDelivery: (state) => state.delivery,
    getAvaliableQuantity: (state) => state.avaliableQuantity,
    getSelectedDate: (state) => state.selectedDate,
    getDate: (state) => state.date,
    getDateValidation: (state) => state.dateValidation
  },
  mutations: {
    setFirstAvaliableDate (state, payload) {
      state.firstAvaliableDate = new Date(payload.replace(/-/g, '/'))
    },
    setQuantity (state, payload) {
      state.avaliableQuantity = payload
    },
    setHolidays (state, payload) {
      if (payload && Object.keys(payload).length) {
        let holidaysList = Object.values(payload)
        state.holidays = holidaysList
      }
    },
    setAvailableDeliveries (state, payload) {
      if (!payload.length) {
        state.availableDeliveries = []
        state.delivery = ''
      }
      if (payload.length === 1 && payload[0] === 'showroom') {
        payload.push('omnipack')
      }
      if (rootStore.state.storeView.tax.defaultCountry === 'DE') {
        let srIndex = payload.indexOf('showroom')
        if (srIndex > -1) {
          payload.splice(srIndex, 1)
        }
      }
      state.availableDeliveries = payload
      state.delivery = payload[0]
      let carrierCode = state.sourceToCarrierMap[state.delivery]
      rootStore.state.product.current.product_option.extension_attributes.carrier_code = carrierCode
    },
    updateDelivery (state, payload) {
      if (state.availableDeliveries.includes(payload)) {
        state.delivery = payload
        let carrierCode = state.sourceToCarrierMap[state.delivery]
        rootStore.state.product.current.product_option.extension_attributes.carrier_code = carrierCode
      }
    },
    updatePeriod (state, payload) {
      if (state.availablePeriods.includes(payload)) {
        state.period = Number(payload)
      }
    },
    // setBookedDates (state, payload) {
    //   let filteredDates = payload.filter(date => {
    //     if (date.q >= state.avaliableQuantity) {
    //       return date
    //     }
    //   })
    //   state.originalBookedDates = filteredDates.map(date => {
    //     return { 'start': date.s, 'end': date.e }
    //   })
    // },
    setBookedDatesFull (state, payload) {
      let filteredDates = payload.filter(date => {
        if (date.q >= state.avaliableQuantity) {
          return date
        }
      })
      state.originalBookedDates = filteredDates.map(date => {
        return { 'start': date.s, 'end': date.s }
      })
    },
    setPeriod (state, payload) {
      // allow only for init set and subscription
      if ((!state.period || payload === 4) && state.availablePeriods && state.availablePeriods.includes(payload)) {
        state.period = Number(payload)
      }
    },
    setAvaliablePeriods (state, payload) {
      state.availablePeriods = payload
    },
    setTurnover (state, { before = 0, after = 0 }) {
      state.turnoverDays.before = before / 24 / 60
      state.turnoverDays.after = after / 24 / 60
    },
    setSelectedDate (state, payload) {
      state.selectedDate = payload
    },
    setDate (state, payload) {
      state.date = payload
    },
    setDateValidation (state, payload) {
      state.dateValidation = payload
    }
  },
  actions: {
    updateCalendarData ({ state }) {
      if (!state.period) return
      let isShowroom = state.delivery === 'showroom'
      let isTryOn = state.period === 3
      let isDelivery = state.delivery === 'omnipack'
      let bookedDates = state.originalBookedDates.map((d) => {
        return {
          end: new Date(d.end.replace(/-/g, '/')),
          start: new Date(d.start.replace(/-/g, '/'))
        }
      })
      if (!state.avaliableQuantity) {
        state.blockedDays = { weekdays: [1, 2, 3, 4, 5, 6, 7] }
        return
      }
      if (!isShowroom && isTryOn) {
        state.blockedDays = { weekdays: [1, 2, 3, 5, 6, 7] }
        state.bookedDatesFull = bookedDates
        return
      }
      if (new Date().getDay() === 6 && state.delivery === 'omnipack') {
        // EG-653
        state.blockedDays = { weekdays: [1, 2] }
        return
      }
      state.blockedDays = { weekdays: [1] }

      // let afterMargin = state.turnoverDays.after
      // let beforeMargin = isDelivery ? state.turnoverDays.before : 0
      // for (let i = 0; i < bookedDates.length; i++) {
      //   let item = bookedDates[i]
      //   item.start.setUTCDate(item.start.getUTCDate() - beforeMargin - afterMargin) // state.period
      //   item.end.setUTCDate(item.end.getUTCDate() + beforeMargin)
      // }
      if (state.holidays.length) {
        let holidays = state.holidays.map((d) => {
          return {
            end: new Date(d.disabled_to.replace(/-/g, '/')),
            start: new Date(d.disabled_from.replace(/-/g, '/'))
          }
        })
        bookedDates = bookedDates.concat(holidays)
      }
      state.bookedDatesFull = bookedDates
    },
    async loadCalendarData ({ state, commit, getters, dispatch, rootGetters }, { sku }) {
      if (!sku) return null
      let delivery = state.sourceToCalendarMap[state.delivery]
      if (!delivery) {
        commit('setQuantity', null)
        dispatch('preparePeriods')
        EventBus.$emit('resetCalendarDate')
        return null
      }
      return new Promise((resolve, reject) => {
        const url = processLocalizedURLAddress(getApiEndpointUrl(config.cart, 'calendardata_endpoint').replace('{{sku}}', encodeURIComponent(sku)).replace('{{delivery}}', delivery))
        TaskQueue.execute({
          url,
          payload: {
            method: 'GET',
            headers: {
              'Accept': 'application/json'
            },
            mode: 'cors'
          }
        }).then(resp => {
          if (resp.code === 200) {
            Logger.info('Booked data loaded')()
            if (resp.result && resp.result[0]) {
              commit('setQuantity', resp.result[0].availableQuantity)
              // commit('setBookedDates', resp.result[0].bookedDatesFull)
              commit('setFirstAvaliableDate', resp.result[0].firstDateAvailable)
              commit('setBookedDatesFull', resp.result[0].bookedDatesFull)
              commit('setHolidays', resp.result[0].disabledDatesFullCalendar)
              dispatch('preparePeriods')
              commit('setTurnover', { before: resp.result[0].turnoverBefore, after: resp.result[0].turnoverAfter })
              dispatch('updateCalendarData')
              resolve(resp.result[0].bookedDatesFull)
            } else {
              reject('Booked data is empty')
            }
          } else {
            reject(resp)
          }
        }).catch(err => {
          reject(err)
        })
      })
    },
    preparePeriods ({ state, commit, getters, dispatch, rootGetters }) {
      if (!rootStore.state.product.current.sirental_price) return
      const avaliablePeriods = rootStore.state.product.current.sirental_price.map(x => x.period && (Number(x.period[0]) + 1))
      commit('setAvaliablePeriods', avaliablePeriods)
      const isSubscriptionAvaliable = rootStore.state.product.current.subscription_points
      const userBalance = rootGetters['user/getBalance']
      if (isSubscriptionAvaliable && userBalance) { // if user have active subscription
        const fourDaysIndex = avaliablePeriods.findIndex(period => period === 4)
        if (fourDaysIndex >= 0) commit('setPeriod', avaliablePeriods[fourDaysIndex])
      } else {
        let defaultIndex = state.availablePeriods.findIndex(period => period === 2) // try to set minimal period first
        defaultIndex = defaultIndex >= 0 ? defaultIndex : 0
        commit('setPeriod', avaliablePeriods[defaultIndex])
      }
    }
  }
}
